/* src/App.css */

/* Импорт шрифтов */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

/* CSS-переменные */
:root {
  --primary-color: #000000;
  --secondary-color: #1a1a1a;
  --background-color: #000000;
  --text-color: #ffff00;
  --accent-color: #ffd700;
  --accent-color-hover: #fffacd;
  --button-hover: #ffd700;
  --border-color: #ffd700;
  --font-primary: "Poppins", sans-serif;
  --font-secondary: "Plus Jakarta Sans", sans-serif;
}

/* Сброс и базовые стили */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: var(--font-primary);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}
body[dir="rtl"] {
  direction: rtl;
}
body[dir="rtl"] .language-switcher .language-button {
  flex-direction: row-reverse;
}
body[dir="ltr"] .language-switcher .language-button {
  flex-direction: row;
}

/* Overlay */
.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: -1;
}

/* Основной контейнер */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 0;
}

/* Переключатель языка */
.language-switcher {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1001;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.language-button {
  display: flex;
  align-items: center;
  background-color: rgba(255, 215, 0, 0.1);
  border: none;
  border-radius: 8px;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  font-family: var(--font-secondary);
}
.language-button.active {
  background-color: rgba(255, 215, 0, 0.2);
  transform: scale(1.05);
}
.language-button:hover {
  background-color: rgba(255, 215, 0, 0.15);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
}
.language-code {
  font-size: 0.9rem;
  font-weight: bold;
}
.language-dropdown {
  position: absolute;
  top: 110%;
  right: 0;
  background-color: rgba(255, 215, 0, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  list-style: none;
  padding: 0.3rem 0;
  width: 140px;
  z-index: 1002;
  animation: fadeInDropdown 0.3s ease-in-out;
  font-family: var(--font-secondary);
}
@keyframes fadeInDropdown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.language-dropdown li {
  width: 100%;
}
.language-option-button {
  width: 100%;
  background: none;
  border: none;
  color: #000;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: var(--font-secondary);
}
.language-option-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Header */
.App-header {
  position: relative;
  color: var(--accent-color);
  padding: 6rem 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: background-color 0.3s;
}
.header-decorative {
  position: absolute;
  background-color: var(--accent-color);
  opacity: 0.6;
  animation: floatDecorative 8s ease-in-out infinite;
}
.decoration-4 {
  top: 40%;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  animation: floatSquare 10s linear infinite;
}
.decoration-5 {
  top: 20%;
  right: 40%;
  width: 30px;
  height: 30px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  animation: floatTriangle 12s linear infinite;
}
@keyframes floatDecorative {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.6;
  }
  50% {
    transform: translateY(-30px) rotate(360deg);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0) rotate(720deg);
    opacity: 0.6;
  }
}
@keyframes floatSquare {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  50% {
    transform: translateX(20px) translateY(-20px) rotate(180deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(360deg);
  }
}
@keyframes floatTriangle {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  50% {
    transform: translateX(-20px) translateY(20px) rotate(-180deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(-360deg);
  }
}
.header-content {
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-content::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid var(--accent-color);
  border-radius: 20px;
  animation: borderGlow 3s infinite;
  z-index: -1;
}
@keyframes borderGlow {
  0% {
    box-shadow: 0 0 5px var(--accent-color);
  }
  50% {
    box-shadow: 0 0 20px var(--accent-color-hover);
  }
  100% {
    box-shadow: 0 0 5px var(--accent-color);
  }
}
.header-content:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.portfolio-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  filter: invert(1) drop-shadow(0 0 10px var(--accent-color));
  transition: transform 0.6s, filter 0.6s;
  cursor: pointer;
}
.portfolio-icon:hover {
  transform: rotateY(360deg);
  filter: invert(1) drop-shadow(0 0 20px var(--accent-color-hover));
}
.studio-title {
  font-size: 3.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 1rem;
  color: var(--accent-color);
  text-shadow: 3px 3px 12px rgba(255, 215, 0, 0.7);
  transition: color 0.3s, text-shadow 0.3s;
  font-family: var(--font-secondary);
}
.studio-title:hover {
  color: var(--accent-color-hover);
  text-shadow: 4px 4px 16px rgba(255, 215, 0, 0.8);
}
.about-me {
  font-size: 1.5rem;
  color: #ffffe0;
  margin-top: 1rem;
  text-shadow: 1px 1px 5px rgba(255, 215, 0, 0.5);
  animation: fadeInText 2s ease-in-out;
  font-family: var(--font-secondary);
}
@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.contact-button-top {
  background-color: var(--accent-color);
  color: var(--background-color);
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  transition: background-color 0.3s, transform 0.3s;
  font-family: var(--font-secondary);
}
.contact-button-top:hover {
  background-color: var(--button-hover);
  transform: translateY(-5px);
}
.contact-button-top .arrow {
  display: inline-block;
  margin-left: 0.5rem;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid var(--background-color);
  animation: bounceArrow 2s infinite;
}
@keyframes bounceArrow {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Projects */
.projects-section {
  padding: 8rem 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.95)
  );
}
.projects-section h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: var(--accent-color);
  text-shadow: 2px 2px 10px rgba(255, 215, 0, 0.7);
  font-family: var(--font-secondary);
}
.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  width: 100%;
  max-width: 1400px;
  padding: 0 2rem;
}
.project-item {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.project-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(255, 215, 0, 0.15);
}
.project-inner {
  position: relative;
  width: 100%;
  padding-top: 75%;
  overflow: hidden;
}
.project-front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}
.project-screenshot {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}
.project-item:hover .project-screenshot {
  transform: scale(1.05);
}
.project-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.8));
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateY(100%);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}
.project-item:hover .project-back {
  transform: translateY(0);
}
.project-back p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease 0.2s;
}
.project-item:hover .project-back p {
  opacity: 1;
  transform: translateY(0);
}
.project-link {
  color: var(--accent-color);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.8rem 1.5rem;
  border: 2px solid var(--accent-color);
  border-radius: 30px;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease 0.3s;
}
.project-item:hover .project-link {
  opacity: 1;
  transform: translateY(0);
}
.project-item:hover .project-link:hover {
  background-color: var(--accent-color);
  color: var(--background-color);
  transform: translateY(-3px);
}

/* Модальное окно */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
}
.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}
.modal-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  animation: zoomIn 0.5s;
}
@keyframes zoomIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.modal-close-button {
  position: absolute;
  top: -30px;
  right: -30px;
  background: transparent;
  border: none;
  color: var(--accent-color);
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
  font-family: var(--font-secondary);
}
.modal-close-button:hover {
  color: var(--button-hover);
  transform: scale(1.2);
}

/* Цены */
.pricing-section {
  padding: 6rem 1rem;
  text-align: center;
  background-color: var(--primary-color);
  transition: background-color 0.3s;
}
.pricing-section h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
  color: var(--accent-color);
  text-shadow: 2px 2px 10px rgba(255, 215, 0, 0.7);
  font-family: var(--font-secondary);
}
.pricing-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
.pricing-card {
  background-color: var(--primary-color);
  padding: 2rem;
  border-radius: 15px;
  width: 300px;
  transition: all 0.3s ease;
  border: 2px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-secondary);
  position: relative;
  overflow: hidden;
}
.pricing-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 215, 0, 0.1),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}
.pricing-card:hover::before {
  transform: translateX(100%);
}
.pricing-icon {
  font-size: 4rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}
.pricing-card h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--accent-color);
}
.price {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: bold;
  margin-top: auto;
}

/* Как я работаю */
.how-i-work-section {
  padding: 6rem 1rem;
  text-align: center;
  background-color: var(--secondary-color);
  color: var(--text-color);
}
.how-i-work-section h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
  color: var(--accent-color);
  text-shadow: 2px 2px 10px rgba(255, 215, 0, 0.7);
  font-family: var(--font-secondary);
}
.steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
.step-card {
  background-color: var(--primary-color);
  padding: 2rem;
  border-radius: 15px;
  width: 300px;
  transition: all 0.3s ease;
  border: 2px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-secondary);
}
.step-card:hover {
  transform: translateY(-10px);
  border-color: var(--accent-color-hover);
}
.step-icon {
  font-size: 4rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}
.step-card h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--accent-color);
}
.step-card p {
  font-size: 1rem;
  color: var(--text-color);
}

/* Навыки */
.skills {
  padding: 4rem 1rem;
  text-align: center;
  background-color: var(--primary-color);
}
.skills h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: var(--accent-color);
  text-shadow: 2px 2px 8px rgba(255, 215, 0, 0.7);
  font-family: var(--font-secondary);
}
.skills ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
.skills li {
  display: flex;
  align-items: center;
  background-color: var(--accent-color);
  color: var(--background-color);
  padding: 1rem 2rem;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: default;
  font-family: var(--font-secondary);
  position: relative;
  overflow: hidden;
}
.skills li::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}
.skills li:hover::before {
  left: 100%;
}
.skills li:hover {
  background-color: var(--button-hover);
  transform: translateY(-8px) scale(1.1);
}

/* Отзывы (Testimonials) */
.testimonials-section {
  padding: 4rem 1rem;
  background-color: var(--secondary-color);
  color: var(--text-color);
  text-align: center;
}
.testimonials-section h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: var(--accent-color);
  text-shadow: 2px 2px 10px rgba(255, 215, 0, 0.7);
  font-family: var(--font-secondary);
}
.testimonial-card {
  background-color: var(--primary-color);
  border: 2px solid var(--border-color);
  border-radius: 15px;
  padding: 2rem;
  margin: 0 1rem;
  text-align: center;
  position: relative;
  /* Зададим минимальную высоту, чтобы при разном тексте карточка не "прыгала" */
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease;
}
.testimonial-card:hover {
  transform: translateY(-10px);
  border-color: var(--accent-color-hover);
}
.testimonial-card::before {
  content: '"';
  position: absolute;
  top: -20px;
  left: 10px;
  font-size: 120px;
  color: rgba(255, 215, 0, 0.1);
  font-family: serif;
  transition: all 0.3s ease;
}
.testimonial-card:hover::before {
  transform: scale(1.1);
  color: rgba(255, 215, 0, 0.2);
}
.quote-icon {
  font-size: 2rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}
.testimonial-text {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  color: var(--text-color);
}
.testimonial-name {
  font-size: 1rem;
  color: var(--accent-color);
  font-weight: bold;
}

/* Контакты */
.contact {
  padding: 6rem 1rem;
  text-align: center;
  border-top: 2px solid var(--border-color);
  background-color: var(--primary-color);
}
.contact h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
  color: var(--accent-color);
  text-shadow: 2px 2px 8px rgba(255, 215, 0, 0.7);
  font-family: var(--font-secondary);
}
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.form-group {
  position: relative;
}
.contact-input,
.contact-textarea,
.styled-select {
  padding: 1.5rem;
  border: 3px solid #333;
  border-radius: 15px;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: 1.2rem;
  transition: border-color 0.3s, background-color 0.3s;
  width: 100%;
  font-family: var(--font-secondary);
}
.contact-input:focus,
.contact-textarea:focus,
.styled-select:focus {
  border-color: var(--accent-color);
  outline: none;
  background-color: var(--secondary-color);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 215, 0, 0.2);
}
.styled-select {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="10" height="7" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 5,7 10,0"/></svg>');
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 10px 7px;
  font-weight: bold;
}
.contact-textarea {
  resize: vertical;
  min-height: 150px;
}
.contact-button {
  padding: 1.5rem;
  border: none;
  background-color: var(--accent-color);
  color: var(--background-color);
  font-size: 1.5rem;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: var(--font-secondary);
  position: relative;
  overflow: hidden;
}
.contact-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}
.contact-button:hover::before {
  left: 100%;
}
.contact-button:hover {
  background-color: var(--button-hover);
  transform: scale(1.1);
}
.error {
  border-color: #ffd700;
}
.error-message {
  color: #ffd700;
  font-size: 0.9rem;
  position: absolute;
  bottom: -1.2rem;
  left: 0;
  font-family: var(--font-secondary);
}
.status-message {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: var(--accent-color);
  font-family: var(--font-secondary);
}

/* Футер-секция (бриф) */
.footer-section {
  background-color: #000;
  color: #fff;
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
}
.footer-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #fff;
  font-weight: bold;
  font-family: var(--font-secondary);
}
.footer-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #bbb;
  font-family: var(--font-secondary);
}
.footer-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-family: var(--font-secondary);
  position: relative;
  overflow: hidden;
}
.footer-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}
.footer-button:hover::before {
  left: 100%;
}
.footer-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}
.footer-button .arrow {
  display: inline-block;
  margin-left: 0.5rem;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #000;
  animation: bounceArrowFooter 2s infinite;
}
@keyframes bounceArrowFooter {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}

/* Основной футер */
footer {
  padding: 3rem 1rem;
  background-color: rgba(26, 26, 26, 0.9);
  color: var(--text-color);
  text-align: center;
  border-top: 3px solid var(--border-color);
  font-family: var(--font-secondary);
}
footer p {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.social-icons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}
.social-icons a {
  color: var(--accent-color);
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}
.social-icons a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: var(--accent-color);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease;
}
.social-icons a:hover::before {
  width: 40px;
  height: 40px;
  z-index: -1;
}

/* Адаптивность */
@media (max-width: 1024px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
@media (max-width: 768px) {
  .studio-title {
    font-size: 2.5rem;
  }
  .about-me {
    font-size: 1rem;
  }
  .projects-section h2,
  .skills h2,
  .contact h2,
  .pricing-section h2,
  .how-i-work-section h2,
  .testimonials-section h2 {
    font-size: 2rem;
  }
  .projects-container {
    grid-template-columns: 1fr;
    gap: 2.5rem;
    padding: 0 1rem;
  }
  .pricing-cards,
  .steps-container {
    flex-direction: column;
    align-items: center;
  }
  .pricing-card,
  .step-card {
    width: 80%;
  }
  .skills ul {
    flex-direction: column;
    align-items: center;
  }
  .skills li {
    width: 80%;
    justify-content: center;
  }
  .language-switcher {
    top: 10px;
    right: 10px;
    gap: 0.2rem;
  }
  .language-button {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
  }
  .modal-close-button {
    top: -30px;
    right: -30px;
  }
  .footer-section {
    padding: 3rem 1rem;
  }
  .footer-content h2 {
    font-size: 2rem;
  }
  .footer-content p {
    font-size: 1rem;
  }
  .footer-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  .footer-button .arrow {
    margin-left: 0.3rem;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #000;
  }
}
@media (max-width: 480px) {
  .studio-title {
    font-size: 2rem;
  }
  .about-me {
    font-size: 0.9rem;
  }
  .projects-container {
    grid-template-columns: 1fr;
  }
  .pricing-card,
  .step-card {
    width: 100%;
  }
  .skills li {
    width: 100%;
  }
  .language-switcher {
    flex-direction: column;
    gap: 0.2rem;
  }
  .language-button {
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
  }
  .modal-close-button {
    top: -30px;
    right: -30px;
  }
  .footer-section {
    padding: 3rem 1rem;
  }
  .footer-content h2 {
    font-size: 2rem;
  }
  .footer-content p {
    font-size: 1rem;
  }
  .footer-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  .footer-button .arrow {
    margin-left: 0.3rem;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #000;
  }
}

/* Плавная прокрутка */
html {
  scroll-behavior: smooth;
}

/* Улучшенные стили для формы */
.contact-input:disabled,
.contact-textarea:disabled,
.styled-select:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: var(--secondary-color);
}

.contact-button.loading {
  position: relative;
  color: transparent;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border: 3px solid var(--background-color);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Улучшенная мобильная навигация */
@media (max-width: 768px) {
  .App-header {
    padding: 4rem 1rem;
  }

  .header-content {
    padding: 1.5rem;
  }

  .studio-title {
    font-size: 2rem;
    letter-spacing: 1px;
  }

  .about-me {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .projects-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0 1rem;
  }

  .project-item {
    margin-bottom: 1rem;
  }

  .pricing-cards,
  .steps-container {
    padding: 0 1rem;
  }

  .pricing-card,
  .step-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .contact-form {
    padding: 0 1rem;
  }

  .contact-input,
  .contact-textarea,
  .styled-select {
    font-size: 16px; /* Предотвращает масштабирование на iOS */
    padding: 1rem;
  }

  .contact-button {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
  }

  /* Улучшенная навигация по секциям */
  section {
    scroll-margin-top: 80px; /* Отступ для фиксированной навигации */
  }

  /* Улучшенные стили для модального окна */
  .modal-content {
    width: 95%;
    margin: 1rem;
  }

  .modal-close-button {
    top: -40px;
    right: 0;
    padding: 0.5rem;
  }

  /* Улучшенные стили для футера */
  footer {
    padding: 2rem 1rem;
  }

  .social-icons {
    gap: 1rem;
  }

  .social-icons a {
    padding: 0.5rem;
  }
}

/* Улучшенные стили для маленьких экранов */
@media (max-width: 480px) {
  .studio-title {
    font-size: 1.8rem;
  }

  .about-me {
    font-size: 0.9rem;
  }

  .projects-section h2,
  .skills h2,
  .contact h2,
  .pricing-section h2,
  .how-i-work-section h2,
  .testimonials-section h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .contact-input,
  .contact-textarea,
  .styled-select {
    padding: 0.8rem;
  }

  .contact-button {
    padding: 0.8rem;
    font-size: 1rem;
  }

  /* Улучшенные стили для слайдера отзывов */
  .testimonial-card {
    padding: 1.5rem;
    margin: 0 0.5rem;
  }

  .testimonial-text {
    font-size: 1rem;
  }

  .testimonial-name {
    font-size: 0.9rem;
  }
}

/* Улучшенные стили для планшетов */
@media (min-width: 769px) and (max-width: 1024px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .pricing-cards,
  .steps-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .contact-form {
    max-width: 800px;
  }
}

/* Улучшенные эффекты для заголовков */
.studio-title::after,
.projects-section h2::after,
.skills h2::after,
.contact h2::after,
.pricing-section h2::after,
.how-i-work-section h2::after,
.testimonials-section h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--accent-color);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.studio-title:hover::after,
.projects-section h2:hover::after,
.skills h2:hover::after,
.contact h2:hover::after,
.pricing-section h2:hover::after,
.how-i-work-section h2:hover::after,
.testimonials-section h2:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Анимация появления элементов */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.project-item,
.pricing-card,
.step-card,
.skills li,
.testimonial-card {
  animation: fadeInUp 0.6s ease forwards;
  opacity: 0;
}

.project-item:nth-child(1) {
  animation-delay: 0.1s;
}
.project-item:nth-child(2) {
  animation-delay: 0.2s;
}
.project-item:nth-child(3) {
  animation-delay: 0.3s;
}
.project-item:nth-child(4) {
  animation-delay: 0.4s;
}
.project-item:nth-child(5) {
  animation-delay: 0.5s;
}
.project-item:nth-child(6) {
  animation-delay: 0.6s;
}
.project-item:nth-child(7) {
  animation-delay: 0.7s;
}
.project-item:nth-child(8) {
  animation-delay: 0.8s;
}
.project-item:nth-child(9) {
  animation-delay: 0.9s;
}

.pricing-card:nth-child(1) {
  animation-delay: 0.1s;
}
.pricing-card:nth-child(2) {
  animation-delay: 0.2s;
}
.pricing-card:nth-child(3) {
  animation-delay: 0.3s;
}

.step-card:nth-child(1) {
  animation-delay: 0.1s;
}
.step-card:nth-child(2) {
  animation-delay: 0.2s;
}
.step-card:nth-child(3) {
  animation-delay: 0.3s;
}
.step-card:nth-child(4) {
  animation-delay: 0.4s;
}
.step-card:nth-child(5) {
  animation-delay: 0.5s;
}
